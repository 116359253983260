import React, { Fragment, useState, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Button, Modal, Card, Spinner } from '@salesforce/design-system-react';
import authService from '../api-authorization/AuthorizeService';
import 'moment/locale/pt-br';
import { LayoutContext } from '../Layout';

export const DeleteModal = forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [target, setCurrentTarget] = useState({});
    const [modalBody, setModalInfo] = useState('');
    const { notifyToast } = useContext(LayoutContext);


    useImperativeHandle(ref, () => ({
        setModalBody(body) {
            setModalInfo(body);
        },
        setTarget(id, objectName) {
            setCurrentTarget({
                id: id,
                objectName: objectName
            });
        },
        toggle() {
            setIsOpen(!isOpen);
        }
    }));

    const onDelete = async () => {
        const token = await authService.getAccessToken();
        const params = new URLSearchParams({
            objectName: target.objectName,
            id: target.id
        }).toString();

        const response = await fetch('basicadmininstration/delete?' + params, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json;"
            },
            method: "DELETE"
        });

        const result = await response.json();

        if (result.success) {
            notifyToast({
                title: 'Registro excluído com sucesso',
                static: false,
                variant: 'success'
            });

            props.onSuccess();

            setIsOpen(!isOpen);
        } else {
            notifyToast({
                title: 'Erro ao excluir registro',
                body: result.errors.join(' \n '),
                static: false,
                variant: 'error'
            });
        }

        setIsLoading(false);
    }

    return (<Fragment>
        <Modal
            isOpen={isOpen}
            footer={[
                <Button label="Cancelar" onClick={() => setIsOpen(!isOpen)} />,
                <Button label="Exlcuir" variant="brand" onClick={() => {
                    setIsLoading(true);
                    onDelete();
                }} disabled={isLoading} />
            ]}
            onRequestClose={() => setIsOpen(!isOpen)}
            heading={""}>

            <Card hasNoHeader={true}>
                {isLoading && (<Spinner size="small" variant="base" />)}
                <div className="slds-p-around_small">
                    Tem certeza que deseja excluir o registro: {modalBody}
                </div>
            </Card>
        </Modal>
    </Fragment>);
});