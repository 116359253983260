import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { LayoutContext } from '../../../Layout';
import CurrencyInput from 'react-currency-input-field';

export const Refund = forwardRef((props, ref) => {
    const { api, notifyToast, matches } = useContext(LayoutContext);
    const [data, setData] = useState({});
    const [sellValue, setSellValue] = useState({});

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            props.setIsLoading(true);
            onSaveFunction();
        }
    }));

    const onSaveFunction = async () => {
        api.doPOST(`payment/refund`, { id: props.id, amount: sellValue.float }).then(result => {
            if (result.success) {
                notifyToast({
                    title: 'Devolução realizada com sucesso',
                    static: false,
                    variant: 'success'
                });

                props.toggle();
            } else {
                notifyToast({
                    title: 'Erro ao realizar devolução',
                    body: result.errors.join(' \n '),
                    static: true,
                    variant: 'error'
                })
            }

            props.setIsLoading(false);
        });
    }

    useEffect(() => {

        const tableListaParam = {
            objectName: 'PaymentRequest',
            id: props.id,
            mode: 'detail'
        };

        const params = new URLSearchParams(tableListaParam).toString();

        api.doGET(`basicadmininstration/getRecord?${params}`).then(result => {
            if (result.success) {
                const datares = {
                    name: result.lines[0].values.find(a => a.fieldName === 'Name').value,
                    amount: result.lines[0].values.find(a => a.fieldName === 'Amount').value
                };

                setData(datares);
            }
        });

    }, [api, props.id]);

    return (<Fragment>
        <div className='slds-grid slds-wrap'>
            <div className='slds-size_1-of-2 slds-p-around_small'>
                <label class="slds-form-element__label">Código</label>
                <input className='slds-input' type='text' disabled='disabled' value={data.name} />
            </div>
            <div className='slds-size_1-of-2 slds-p-around_small'>
                <label class="slds-form-element__label">Amount</label>
                <CurrencyInput
                    intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                    value={data.amount}
                    decimalsLimit={2}
                    className="slds-input inputSearch"
                    decimalSeparator="," groupSeparator="."
                    allowDecimals={true}
                    readonly={true}
                />
            </div>
            <div className='slds-size_1-of-2 slds-p-around_small'>

                <label class="slds-form-element__label"><abbr class="slds-required" title="required">* </abbr> Valor da devolução</label>
                <CurrencyInput
                    intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                    value={sellValue?.displayValue ?? sellValue.value}
                    decimalsLimit={2}
                    onValueChange={(value, name, values) => {
                        setSellValue(values);
                    }}
                    className="slds-input inputSearch"
                    decimalSeparator="," groupSeparator="."
                    allowDecimals={true}
                />
            </div>
        </div>

    </Fragment>)
});