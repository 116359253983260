export const Operators = [
    { label: 'Igual a', value: 'equals' },
    { label: 'Não igual a', value: 'notequals' },
    { label: 'Menor que', value: 'lessthan' },
    { label: 'Maior que', value: 'greaterthan' },
    { label: 'Menor ou igual a', value: 'lessorequal' },
    { label: 'Maior ou igual a', value: 'greaterorequal' },
    { label: 'Contém', value: 'contains' },
    { label: 'Não contém', value: 'notcontains' },
    { label: 'Começa com', value: 'startswith' },
];