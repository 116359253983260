import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Input, Button } from '@salesforce/design-system-react';
import { LayoutContext } from '../../Layout';

export const UpdatePricebookEntries = forwardRef((props, ref) => {

    const [data, setData] = useState({});
    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() { return false; },
        onSave() { }
    }));

    const requestUpdateEntries = async () => {
        const result = await api.doGET('pricebook/update-entries');
        setData(result);
        props.setIsLoading(false);

        if (result.success) {
            notifyToast({
                title: 'Tabela de preços atualizada com sucesso',
                static: false,
                variant: 'success'
            });
        } else {
            notifyToast({
                title: 'Erro ao atualizar tabela de preços',
                static: false,
                variant: 'error'
            });
        }
    }

    const updateEntries = () => {
        props.setIsLoading(true);
        requestUpdateEntries();
    }

    return (<Fragment>
        <span>Deseja realmente atualizar os preços com o Mercado Steam?</span>
        <Button label="Atualizar" onClick={() => updateEntries()} className='slds-m-left_small' variant="brand"/>
    </Fragment>);
});