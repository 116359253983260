import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Input,Button } from '@salesforce/design-system-react';
import { LayoutContext } from '../../Layout';

export const BotTwoFactor = forwardRef((props, ref) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [form, setForm] = useState({ userName: "", password: "", code: "", smsCode: '', id: props.id });
    const [displayCodeField, setDisplayCodeField] = useState(false);
    const [displaySmsCodeField, setDisplaySmsCodeField] = useState(false);
    const [data, setData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return currentStep < 2 && !data?.twoFactorEnabled;
        },
        onSave() {
            onSaveFunction();
        }
    }));

    useEffect(() => {
        props.setIsLoading(true);
        props.callBack();
        getBotConfiguration();
    }, []);

    const onchangeForm = (newUserName, newPassword, newCode, newSmsCode) => {
        setForm({
            userName: newUserName,
            password: newPassword,
            code: newCode,
            smsCode: newSmsCode,
            id: props.id
        });
    }

    const onSaveFunction = async () =>
    {
        if (data.twoFactorEnabled) {
            props.toggle();
        } else { 

            if (currentStep === 0) {
                setCurrentStep(currentStep + 1);
            } else if (currentStep === 1) {
                props.setIsLoading(true);
                onTryEnableAuth();
            }
        }

        props.callBack();
    }

    const getBotConfiguration = async () => {
        const result = await api.doGET('bot/get-bot?id=' + form.id, form);

        setData(result);
        props.setIsLoading(false);
        props.callBack();
    }

    const onTryEnableAuth = async () => {
        const result = await api.doPOST('bot/create-two-factor', form);

        if (result.success) {
            if (result.errorCode === 'REQUIRESMS') {
                setDisplaySmsCodeField(true);
                setDisplayCodeField(false);
                setErrorMessage('');
            } else if (result.errorCode === 'ENABLED') {
                setCurrentStep(2);
            }
        } else {
            if (result.errorCode === 'SteamGuard') {
                setDisplayCodeField(true);
            } else {
                setDisplayCodeField(false);
            }

            setErrorMessage(`${result.errorCode} ${result.errors[0]}`);
        }

        props.setIsLoading(false);
    };

    if (data?.twoFactorEnabled) {
        return (<Fragment>
            <span>Two factor já esta habilitado</span>
        </Fragment>)
    } else if (currentStep === 0) {
        return (<Fragment>
            <span>Deseja realmente criar duplo fator de autenticação?</span>
        </Fragment>);
    } else if (currentStep === 1) {
        return (<Fragment>
            <div className='slds-grid slds-wrap'>
                <div className='slds-col slds-size_1-of-1'>
                    <span className="slds-text-color_error">{errorMessage}</span>
                </div>
                <div className='slds-col slds-size_1-of-1 slds-p-around_small'>
                    <Input
                        required={true}
                        value={form.username}
                        label="Nome do usuário"
                        placeholder="Usuário steam"
                        onChange={(e) => onchangeForm(e.target.value, form.password, form.code, form.smsCode)} />
                </div>
                <div className='slds-col slds-size_1-of-1 slds-p-around_small'>
                    <Input
                        required={true}
                        value={form.username}
                        label="Senha"
                        placeholder="Senha da steam"
                        onChange={(e) => onchangeForm(form.userName, e.target.value, form.code, form.smsCode)} />
                </div>
                {displayCodeField && (<Fragment>
                    <div className='slds-col slds-size_1-of-1 slds-p-around_small'>
                        <Input
                            required={true}
                            value={form.username}
                            label="Código de verificação (E-MAIL)"
                            placeholder="Código de verificação da Steam"
                            onChange={(e) => onchangeForm(form.userName, form.password, e.target.value, form.smsCode)} />
                    </div>
                </Fragment>)}
                {displaySmsCodeField && (<Fragment>
                    <div className='slds-col slds-size_1-of-1 slds-p-around_small'>
                        <Input
                            required={true}
                            value={form.username}
                            label="Código de verificação (SMS)"
                            placeholder="Código de verificação da Steam"
                            onChange={(e) => onchangeForm(form.userName, form.password, form.code, e.target.value)} />
                    </div>
                </Fragment>)}
            </div>

        </Fragment>);
    } else if (currentStep === 2) {

        return (<Fragment><span>TWO-FACTOR HABILITADO COM SUCESSO</span></Fragment>);

    }
});

export const BotConfirmationCode = forwardRef((props, ref) => {
    const [data, setData] = useState({});
    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() { return false; },
        onSave() { }
    }));

    const getSteamGuard = async () => {
        const result = await api.doGET('bot/get-steam-guard?id=' + props.id);
        setData(result);
        props.setIsLoading(false);
    }

    const updateCode = () => {
        props.setIsLoading(true);
        getSteamGuard();
    }

    useEffect(() => {
        updateCode();
    }, []);

    return (<Fragment>
        <span>Steam guard code: {data.code}</span>
        <Button label="Atualizar" onClick={() => updateCode()} className='slds-m-left_small' variant="brand" />
    </Fragment>);
});