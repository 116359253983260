import React, { Fragment, useState, useEffect, createContext, useContext, useRef } from 'react';
import { Input, Card, Button, Spinner } from '@salesforce/design-system-react';
import authService from './AuthorizeService';
import { LoginActions } from './ApiAuthorizationConstants';
import { Login } from './Login';
import BrSkinsLogo from '../../customstyles/logo/br-skins-logo-star-fade.png';
import GoogleLogo from '../../customstyles/logo/Google-g-logo.svg';

export const Signin = (props) => {

    const trySigin = () => {
        authService.authorize();
    }

    return (<Fragment>

        <div className="slds-grid slds-grid_vertical slds-align_absolute-center">
            <Card id='loginCard' style={{ width: '300px' }} hasNoHeader={true}>
                <div className="slds-grid slds-wrap slds-p-around_small">
                    <div className='slds-col slds-size_1-of-1 slds-text-heading_large slds-text-align_center'>
                        PORTAL BRSKINS
                    </div>
                    <div className='slds-col slds-size_1-of-1 slds-text-align_center'>
                        <img src={BrSkinsLogo} width='150px' height='150px'></img>
                    </div>

                    <div className='slds-col slds-size_1-of-1 slds-p-top_small'>
                        <Button label="Login com google" variant="brand" className='slds-button_stretch slds-p-around_x-small' onClick={() => trySigin()}>
                            <img src={GoogleLogo} className='slds-p-left_small'></img>
                        </Button>
                    </div>
                </div>  
            </Card>
        </div>
    </Fragment>);
};