import React, { Fragment, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    DataTable, DataTableCell, DataTableRowActions, PageHeader, Icon, DropdownTrigger, Button, Dropdown,
    DataTableColumn, IconSettings, PageHeaderControl, ButtonGroup, Spinner
} from '@salesforce/design-system-react';
import { useSearchParams, useParams } from 'react-router-dom';
import { EditFormModal } from './EditForm';
import { DeleteModal } from './DeleteModal';
import { LayoutContext } from '../Layout';
import { TableFilter } from './filters/TableFilter';
import { OutputText } from './OutputText';
import { CustomAction } from './actions/CustomAction';
import eventManager from '../common/ExtensionLibrary';

export const TableList = (props) => {
    const { object } = useParams();
    const [mode, setMode] = useState(props.mode ?? 'ListView');
    const [searchParams, setSearchParams] = useSearchParams();

    const urlFiltersCallback = useCallback(() => {
        const urlFilters = [];

        for (const [key, value] of searchParams) {
            urlFilters.push({
                fieldName: key,
                filterCondition: 'equals',
                filterValue: value
            });
        };

        return urlFilters;
    }, [searchParams]);

    const currFilters = urlFiltersCallback();
    const [tableParams, setTableParams] = useState({
        objectName: props.objectName ?? object,
        fieldName: props.fieldName ,
        filters: props.filters ?? currFilters,
        pageSize: props.pageSize ?? 50
    });

    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFixedHeader, setIsfixedHeader] = useState(true);
    const [gridLoading, setGridLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [data, setData] = useState({});
    const editFormModal = useRef(null);
    const deleteModal = useRef(null);
    const customActionModal = useRef(null);
    const filterRef = useRef(null);
    const { notifyToast, api } = useContext(LayoutContext);

    const getRecords = useCallback((currentPageParam, objectName, pageSize, filters) => {
        if (!objectName) {
            return;
        }

        const tableListaParam = {
            objectName: objectName,
            pagination: { pageSize: pageSize, pageIndex: currentPageParam },
            filters: filters
        };

        setLoading(true);

        api.doPOST('basicadmininstration/list', tableListaParam).then(result => {
            let newItems = [];

            if (result.success) {

                result.lines.forEach((line) => {
                    const item = {};
                    line.values.forEach((column) => {
                        if (column?.displayValue) {
                            item[column.fieldName + 'ValueCode'] = column?.value?.toString();
                        } else if (column.fieldName === 'Name') {
                            item[column.fieldName + 'ValueCode'] = line.values.find(a => a.fieldName === 'id').value;
                        }

                        item[column.fieldName] = column?.displayValue ?? column?.value?.toString();
                    });
                    newItems.push(item);
                });

                if (mode === 'ListView') {
                    setHasMore(result.hasMoreLines);
                }
            } else {
                notifyToast({
                    title: 'Erro ao listar items',
                    body: result.errors.join(' \n '),
                    static: false,
                    variant: 'error'
                })
            }
            setItems(oldItems => {
                newItems.forEach(item => oldItems.push(item));
                return oldItems;
            });
            setData(result);
            setLoading(false);
            setGridLoading(false);
        });

    }, []);

    useEffect(() => {
        setItems([]);
        setCurrentPage(0);
        setHasMore(false);
        setLoading(false);
        setGridLoading(true);

        if (object && mode === 'ListView') {
            if (tableParams.objectName !== object) {
                setData({});
                setTableParams(oldTableParam => {
                    oldTableParam.objectName = object;
                    oldTableParam.filters = [];
                    return oldTableParam;
                });
            }
        }

        eventManager.notifySubscribers('SetCurrentObject', object);

    }, [object, mode, searchParams, tableParams.objectName])

    useEffect(() => {
        getRecords(currentPage, tableParams.objectName, tableParams.pageSize, tableParams.filters);
    }, [currentPage, tableParams.pageSize, getRecords, tableParams.objectName, tableParams.filters]);

    useEffect(() => {
        if (!isFixedHeader) {
            setIsfixedHeader(true);
        }
    }, [isFixedHeader])

    const onRefresh = async () => {

        setGridLoading(true);
        setItems([]);

        if (currentPage > 0) {
            setCurrentPage(0);
        } else {
            getRecords(0, tableParams.objectName, tableParams.pageSize, tableParams.filters);
        }
    }

    const onFilter = async () => {
        await changeFixedHeader(false);
        filterRef.current.toggle();
    }

    const onSaveFilter = async (savedFilters) => {
        const newFilter = [];

        props?.filters?.forEach((filter) => {
            newFilter.push({
                fieldName: filter.fieldName,
                filterCondition: filter.filterCondition,
                filterValue: filter.filterValue
            });
        });

        savedFilters.forEach((filter) => {
            newFilter.push({
                fieldName: filter.nameValue,
                filterCondition: filter.predicateValue,
                filterValue: filter.value
            });
        });

        setLoading(true);
        setItems([]);
        setCurrentPage(0);
        setTableParams(oldTableParam => {
            oldTableParam.filters = newFilter;
            return oldTableParam;
        });
    }

    const changeFixedHeader = async (state) => {
        setIsfixedHeader(state);
    }

    const handleRowAction = (event, action) => {
        if (action.standard) {
            if (action.apiName === 'Delete') {
                deleteModal.current.setModalBody(event.Name);
                deleteModal.current.setTarget(event.id, tableParams.objectName);
                deleteModal.current.toggle();

            } else if (action.apiName === 'Update') {
                editFormModal.current.setTitle('Editar ' + tableParams.objectName)
                editFormModal.current.setTarget(event.id, tableParams.objectName);
                editFormModal.current.toggle();
            }
        } else {
            customActionModal.current.setTitle(action.name);
            customActionModal.current.setTarget(event.id, tableParams.objectName, action);
            customActionModal.current.toggle();
        }
    }

    const handleHeaderAction = (event) => {

        if (event.standard) {
            if (event.apiName === 'Create') {
                let defineFiels = [];

                if (mode === 'ListView') {
                    for (const [key, value] of searchParams) {
                        defineFiels.push({ fieldName: key, value: value });
                    };
                } else {
                    const paramValue = tableParams.filters.find(a => a.fieldName === tableParams.fieldName);
                    if (paramValue) {
                        defineFiels.push({ fieldName: tableParams.fieldName, value: paramValue.filterValue });
                    }
                }

                editFormModal.current.setTitle('Novo ' + tableParams.objectName)
                editFormModal.current.setTarget(null, tableParams.objectName, defineFiels);
                editFormModal.current.toggle();
            }
        } else {
            customActionModal.current.setTitle(event.name);
            customActionModal.current.setTarget(null, tableParams.objectName, event);
            customActionModal.current.toggle();
        }
    }

    const handleLoadMore = (event) => {
        if (!loading && hasMore) {
            setLoading(true);
            setCurrentPage(currentPage + 1);
        }
    }

    const userRowActions = [];
    let actionId = 0;

    data?.actions?.forEach((action,idx) => {
        if (action.type === 'DetailLayout') {
            userRowActions.push({ id: actionId, label: action.name, value: action.apiName, ...action});
            actionId++
        }
    });

    CustomColumn.displayName = DataTableCell.displayName;

    const columns = data?.fields?.map((column) => {
        return (<DataTableColumn key={data.name + column.name} label={column.label} property={column.name}>
            <CustomColumn type={column.type} objectName={tableParams.objectName} />
        </DataTableColumn>);
    }) ?? [];

    const height = mode === 'ListView' ? 'calc(100vh - 220px)' : ''; 

    const pageHeaderProps = mode === 'ListView' ? {
        onRenderActions: () => actions({ data: data, onActionClick: handleHeaderAction }),
        icon: (< Icon assistiveText={{ label: 'User' }} category="standard" name="product" />),
        info: items.length + " itens",
        joined: true,
        label: data.name,
        onRenderControls: () => controls({ refresh: onRefresh, onFilter: onFilter }),
        title: (< h1 className="slds-page-header__title slds-p-right_x-small" >
            <Dropdown
                options={[]}>
                <DropdownTrigger>
                    <Button
                        className="slds-button_reset slds-type-focus"
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label="Todos os registros"
                        responsive
                        variant="base"
                    />
                </DropdownTrigger>
            </Dropdown></h1 >),
        truncate: true,
        variant: "object-home"
    } : {
            onRenderActions: () => actions({ data: data, onActionClick: handleHeaderAction }),
            joined: true,
            label: 'Registros relacionados',
            icon: (< Icon assistiveText={{ label: 'User' }} category="standard" name="product" />),
            title: (< h1 className="slds-page-header__title slds-p-right_x-small" >{data.name + 's'}</h1>),
            variant: "object-home"
    };

    return (<Fragment>
        <EditFormModal ref={editFormModal} onSuccess={() => onRefresh()}></EditFormModal>
        <DeleteModal ref={deleteModal} onSuccess={() => onRefresh()}></DeleteModal>
        <CustomAction ref={customActionModal} onSuccess={() => onRefresh()} />

        <IconSettings iconPath="/assets/icons">
            <div className='slds-is-relative' style={{ height: height }}>
                <PageHeader {...pageHeaderProps} />
                {gridLoading && (<Spinner size="medium" variant="base" />)}
                <div className='slds-grid' style={{ height: height }}>
                    <div className='slds-col' style={{ height: height }}>
                        <DataTable
                            fixedHeader={mode === 'ListView'}
                            fixedLayout={true}
                            joined={true}
                            items={items}
                            onLoadMore={handleLoadMore}
                            hasMore={hasMore}>
                            {columns}
                            <DataTableRowActions options={userRowActions}
                                menuPosition="overflowBoundaryElement"
                                onAction={handleRowAction}
                                dropdown={<Dropdown />} />
                        </DataTable>
                    </div>
                    <div className='slds-col slds-col_bump-left'>
                        <TableFilter ref={filterRef} fields={data.fields} object={object} onSave={onSaveFilter} />
                    </div>
                </div>
            </div>
        </IconSettings>
    </Fragment>);
}

const CustomColumn = ({ children, ...props }) => {
    return (<DataTableCell {...props}>
        <OutputText {...props}>
            {children}
        </OutputText>
    </DataTableCell>);
};

const actions = (props, idx) => {

    const headerActions = props?.data?.actions?.filter(a => a.type === "ListView").map(action => {
        return (<Button label={action.name} onClick={() => props.onActionClick(action)} key={'hact' + idx} />);
    });
    return (<PageHeaderControl>
        <ButtonGroup id="button-group-page-header-actions">
            {headerActions}
        </ButtonGroup>
    </PageHeaderControl>
)};

const controls = (props) => (
    <Fragment>
        <PageHeaderControl>
            <Button
                assistiveText={{ icon: 'Refresh' }}
                iconCategory="utility"
                iconName="refresh"
                iconVariant="border-filled"
                variant="icon"
                onClick={() => props.refresh()}
            />
        </PageHeaderControl>
        <PageHeaderControl>
            <Button
                assistiveText={{ icon: 'Filters' }}
                iconCategory="utility"
                iconName="filterList"
                iconVariant="border-filled"
                variant="icon"
                onClick={() => props.onFilter()}
            />
        </PageHeaderControl>
    </Fragment>
);