import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Input, Button } from '@salesforce/design-system-react';
import { LayoutContext } from '../../../Layout';
import { Paginator, DataTable } from '../../../common/react-ext/brskins-react-strap';


export const SkinPlaceInventoryList = forwardRef((props, ref) => {
    const [data, setData] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const { api, notifyToast, matches } = useContext(LayoutContext);
    const [pagination, setPagination] = useState({ pageSize: 100, totalRecords: 0, pageIndex: 0 });
    const [selectedAssets, setSelectedAssets] = useState({});

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            props.setIsLoading(true);
            onSaveFunction();
        }
    }));

    useEffect(() => {
        props.setSize('large');
        props.setIsLoading(true);
        props.callBack();
        getBotInventory();
    }, []);

    const onSaveFunction = async () => {

        let arrItems = [];
        let selectedAssetCodes = [];
        Object.keys(selectedAssets).forEach((key) => {
            if (selectedAssets[key].selected) {
                arrItems.push({
                    assetCode: key,
                    price: selectedAssets[key].row.skinPlacePrice
                });
                selectedAssetCodes.push(key);

            }
        });

        const form = {
            botId: props.id,
            assetCodes : arrItems
        };

        const result = await api.doPOST('offer/create-skinplace-trade', form);

        if (result.success) {
            data.inventoryItems = data.inventoryItems.filter(a => !selectedAssetCodes.includes(a.assetCode));
            setData({ ...data });

            notifyToast({
                title: 'Sucesso',
                body: 'Skins vendidas com sucesso',
                static: false,
                variant: 'success'
            });
            setSelectedAssets({});
        } else {
            setErrorMessage(`${result.errorCode} ${result.errors[0]}`);
        }

        props.setIsLoading(false);
        props.callBack();
    }

    const onChangeSelected = (row) => {
        if (selectedAssets[row.assetCode]) {
            selectedAssets[row.assetCode] = {
                selected: !selectedAssets[row.assetCode].selected,
                row: row
            };
        } else {
            selectedAssets[row.assetCode] = {
                selected: true,
                row: row
            };
        }

        setSelectedAssets({ ...selectedAssets })
    }

    const columns = [
        {
            fieldName: 'isSelected', label: '', type: 'Custom', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            },
            customCell: CustomCell,
            events: onChangeSelected
        },
        {
            fieldName: 'name', label: 'Nome', class:'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'assetCode', label: 'AssetCode', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            }        },
        {
            fieldName: 'skipReason', label: 'SkipReason', class: 'slds-text-color_default', cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'offerDate', type: 'Date', label: 'offer date', class: 'slds-text-color_default', cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'offerPrice', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Valor anúncio', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinPlacePrice', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Valor Skin.Place', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinPurchasePrice', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Preço de compra', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinSellProfit', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Lucro venda', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinSellProfitPercentage', type: 'percent', locale: 'pt-BR', label: '% Lucro venda', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinExpectedProfit', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Lucro venda anúncio', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'skinExpectedProfitPercentage', type: 'percent', locale: 'pt-BR', label: '% Lucro venda anúncio', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'sellAntecipationLostPrice', type: 'currency', locale: 'pt-BR', currency: 'BRL', label: 'Valor de perda', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        }
    ];

    const getBotInventory = async () => {
        const result = await api.doGET(`offer/get-skinplace-inventory?botId=${props.id}`);

        setData(result);
        props.setIsLoading(false);
        props.callBack();
    }


    const totalPages = pagination.totalRecords / pagination.pageSize;

    const changePage = (pageIndex) => {
        if (pagination.pageIndex !== pageIndex) {
            setPagination({ pageSize: pagination.pageSize, totalRecords: pagination.totalRecords, pageIndex: pageIndex });
        }
    }
     
    return (<Fragment>
        <div className='slds-text-color_default' style={{ overflowX: 'scroll' }}>
            {errorMessage && (
                <div className='slds-col slds-size_1-of-1'>
                    <span className="slds-text-color_error">{errorMessage}</span>
                </div>
            )}
            {data.success && (<Fragment>
                <DataTable
                    columns={columns}
                    data={data.inventoryItems}
                    striped={true}
                    cardMode={!matches}
                    keyColumn={"assetCode"}
                >
                </DataTable>
                <Paginator totalPages={totalPages} onClick={changePage} isLoading={props.isLoading}></Paginator>
            </Fragment>)}
        </div>
    </Fragment>);
});

export const CustomCell = (props) => {
    const [isSelected, setSelected] = useState(false);

    const handleInputChange = (e) => {
        setSelected(e.checked);
        props.events(props.row);
    }

    return <Fragment>
        <input type='checkbox' checked={isSelected} onClick={(e) => handleInputChange(e)}></input>
    </Fragment>
}