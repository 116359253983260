import { BotTwoFactor, BotConfirmationCode } from '../object-actions/BotTwoFactor';
import { UpdatePricebookEntries } from '../object-actions/UpdatePricebookEntries';
import { UpdateAllOffer } from '../object-actions/UpdateAllOffer';
import { LayoutConfiguration } from '../object-actions/LayoutConfiguration';
import { SkinPlaceInventoryList } from '../object-actions/skin-place/skin-place-inventory';
import { PendingBotOffers } from '../object-actions/Bot/bot-trade-offer';
import { AssetTransfer } from '../object-actions/Asset/asset-transfer';
import { Refund } from '../object-actions/payments/PaymentRefund';

export const Actions = {
    "BotTwoFactor": BotTwoFactor,
    "BotConfirmationCode": BotConfirmationCode,
    "UpdatePricebookEntries": UpdatePricebookEntries,
    "UpdateAllOffer": UpdateAllOffer,
    "LayoutConfiguration": LayoutConfiguration,
    "SkinPlaceInventoryList": SkinPlaceInventoryList,
    "PendingBotOffers": PendingBotOffers,
    AssetTransfer: AssetTransfer,
    Refund: Refund
}