import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Input, Button } from '@salesforce/design-system-react';
import { LayoutContext } from '../../Layout';

export const UpdateAllOffer = forwardRef((props, ref) => {

    const [data, setData] = useState({});
    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() { return false; },
        onSave() { }
    }));

    const requestUpdateOffers = async () => {
        const result = await api.doGET('offer/update-score');
        setData(result);
        props.setIsLoading(false);

        if (result.success) {
            notifyToast({
                title: 'Ofertas atualizadas com sucesso',
                static: false,
                variant: 'success'
            });
        } else {
            notifyToast({
                title: 'Erro ao atualizar Ofertas',
                static: false,
                variant: 'error'
            });
        }
    }

    const updateOffers = () => {
        props.setIsLoading(true);
        requestUpdateOffers();
    }

    return (<Fragment>
        <span>Deseja realmente atualizar as offers?</span>
        <Button label="Atualizar" onClick={() => updateOffers()} className='slds-m-left_small' variant="brand" />
    </Fragment>);
});