class EventManager{
    currentEvent = null;
    nextEvent = null;
    _subscribes = {};

    enqueueEvent (event, callback) {
        if (!this.currentEvent) {
            this.currentEvent = {
                event: event, callback: callback
            };

            this.fireCurrentEvent();

        } else {
            this.nextEvent = {
                event: event, callback: callback
            };
        }
    }

    fireCurrentEvent() {

        if (this.currentEvent) {
            this.currentEvent.event().then(result => {
                this.currentEvent?.callback(result);
                this.currentEvent = this.nextEvent;
                this.nextEvent = null;

                if (this.currentEvent) {
                    const currentContext = this;
                    setTimeout(function() {
                        currentContext.fireCurrentEvent();
                    }, 1000);
                }
            });
        }
    }

    subscribe(eventName, callback) {
        if (!this._subscribes[eventName]) {
            this._subscribes[eventName] = [];
        }

        this._subscribes[eventName].push(callback);
    }

    unsubscribe(eventName, callback) {
        if (callback && this._subscribes[eventName] && this._subscribes[eventName].includes(callback)) {
            this._subscribes[eventName] = this._subscribes[eventName].filter(item => item !== callback);
        }
    }

    async notifySubscribers(eventName, params) {
        if (this._subscribes[eventName]) {
            this._subscribes[eventName].forEach((event) => event(params));
        }
    }
}

const eventManager = new EventManager();

export default eventManager;