import React, { forwardRef, Fragment, useState, useImperativeHandle, useEffect } from "react";
import './slds-react.css'; 

export const Toast = forwardRef((props, ref) => {

    const [state, setState] = useState({ });
    const [isOpen, setOpen] = useState(false);
    const timeout = state.fadeTime ?? 5;
    const symbols = '/assets/icons/utility-sprite/svg/symbols.svg';

    useImperativeHandle(ref, () => ({
        fire(stateRef) {
            setState(stateRef);
            setOpen(true);
        }
    }));

    useEffect(() => {
        if (!state.static) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, timeout * 1000);

            return () => clearTimeout(timer); 
        }
    }, [state]);

    const variant = state.variant ?? 'info'

    const container = classStringName({
        'slds-notify_container': true,
        'slds-region_narrow': state.small === true,
        'slds-is-absolute': true
    });

    const statusClass = classStringName({
        'slds-notify': true,
        'slds-notify_toast': true,
        'slds-theme_success': variant === 'success',
        'slds-theme_info': variant === 'info',
        'slds-theme_error': variant === 'error'
    });

    const iconClass = classStringName({
        'slds-icon_container': true,
        'slds-icon-utility-success': variant === 'success',
        'slds-icon-utility-info': variant === 'info',
        'slds-icon-utility-error': variant === 'error',
        'slds-m-right_small': true,
        'slds-no-flex': true
    });

    return (<Fragment>
        {isOpen && (<div className={container}>
            <div className={statusClass} role="status">
                <span className="slds-assistive-text">success</span>
                <span className={iconClass} title={state?.iconTitle }>
                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                        <use xlinkHref={symbols + "#" + state.variant}></use>
                    </svg>
                </span>
                <div className="slds-notify__content">
                    <div className="slds-text-heading_small">
                        {state.title}
                    </div>
                    {state.body && (<p>{state.body}</p>)}
                </div>
                <div className="slds-notify__close">
                    <button className="slds-button slds-button_icon slds-button_icon-inverse" title="Close" onClick={() => setOpen(!isOpen)}>
                        <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlinkHref={symbols + "#close"}></use>
                        </svg>
                        <span className="slds-assistive-text">{state.closeAssistiveText}</span>
                    </button>
                </div>
            </div>
        </div>)}
    </Fragment>);
});

export const Tab = ({ children, ...attributes }) => {
    return (
        <Fragment>
            <div className="slds-tabs_default"  {...attributes}>
                <ul className="slds-tabs_default__nav " role="tablist">
                    {children}
                </ul>
            </div>
        </Fragment>
    );
};

export const TabItem = ({ children, ...attributes }) => {

    let imageItemClasses = classStringName({
        'slds-is-active': attributes.selected,
        'slds-tabs_default__item': true,
        'slds-nowrap': true,
        'slds-text-align_center': true,
        'slds-text-color_inverse': true,
        'defaultSelected': true
    });

    return (
        <Fragment>
            <li className={imageItemClasses} {...attributes}>
                {children}
            </li>
        </Fragment>
    );
};

export const TabLink = ({ children, ...attributes }) => {
    return (
        <Fragment>
            <a className="slds-tabs_default__link" {...attributes}>
                {children}
            </a>
        </Fragment>
    );
};

export const Tree = ({ children, ...attributes }) => {
    return (
        <Fragment>
            <div className="slds-tree_container">
                <h4 className="slds-tree__group-header">{attributes.HeaderText}</h4>
                <ul aria-labelledby="treeheading" className="slds-tree" role="tree">
                    {children }
                </ul>
            </div>
        </Fragment>
    );
};

export const TreeHeader = ({ children, ...attributes }) => {
    return (
        <Fragment>
            <h4 className="slds-tree__group-header">{children}</h4>
        </Fragment>
    );
};

export const TreeItem = ({ children, ...attributes }) => {
    const [isCollapsed, setCollapsed] = useState(attributes.isCollapsed ?? false);
    const symbols = '/assets/icons/utility-sprite/svg/symbols.svg';
    return (<Fragment>
        <li aria-expanded={isCollapsed} aria-label={attributes.title} aria-level={attributes.ariaLevel} role="treeitem">
            <div className="slds-tree__item" onClick={() => setCollapsed(!isCollapsed) }>
                <div className="slds-tree__item-label slds-truncate" title={attributes.title}>
                    {attributes.value}
                </div>
                {attributes.hasSubItems && (<Fragment>
                    <div className='slds-col_bump-left'></div>
                    {attributes.valueBump}
                    <button className="slds-button slds-button_icon slds-m-horizontal_x-small" tabIndex="-1" title={attributes.title}>
                        <svg className="slds-button__icon slds-button__icon_small">
                            <use xlinkHref={symbols + "#chevronright"} fill="#fff"></use>
                        </svg>
                        <span className="slds-assistive-text">{attributes.assistiveText}</span>
                    </button>
                </Fragment>)}
            </div>
            {attributes.hasSubItems && isCollapsed && (<Fragment>
                <ul role="group">
                    {children}  
                </ul>
                <hr/>
            </Fragment>)}
        </li>
    </Fragment>);
}

function classStringName(strNames) {
    let strClasses = '';
    Object.keys(strNames).forEach((key) => {
        if (strNames[key] === true) {
            strClasses += ' ' + key;
        }
    });
    return strClasses;
}