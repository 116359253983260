import React, { Fragment, useImperativeHandle, forwardRef, useState } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { Navigate } from "react-router-dom";

export const ApiRequester = forwardRef((props, ref) => {
    const [redirect, setRedirect] = useState(false);

    useImperativeHandle(ref, () => ({
        doGET(requestURL) {
            return new Promise((resolve, reject) => {
                tryGET(requestURL).then((response) => {
                    resolve(response);
                })
            })
        },
        doPOST(requestURL, body) {
            return new Promise((resolve, reject) => {
                tryPOST(requestURL, body).then((response) => {
                    resolve(response);
                })
            })
        }
    }));

    const tryGET = async (requestURL) => {
        const token = await authService.getAccessToken();

        const headers = !token ? { "Content-Type": "application/json;" } : {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json;"
        };

        const response = await fetch(requestURL, {
            headers: headers,
            method: "GET"
        });

        if (await response.status === 401) {
            setRedirect(true);
        } else {
            return await response.json();
        }
    }

    const tryPOST = async (requestURL, body) => {
        const token = await authService.getAccessToken();

        const headers = !token ? { "Content-Type": "application/json;" } : {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json;"
        };

        const response = await fetch(requestURL, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(body)
        });

        if (await response.status === 401) {
            setRedirect(true);
        } else {
            return await response.json();
        }
    }

    return (<Fragment>
        {redirect && (<Navigate to={`${ApplicationPaths.LogOutCallback}`} />)}
    </Fragment>);
})

