import React, { Fragment, useState, useReducer, useRef, forwardRef, useImperativeHandle } from 'react';
import { Card, Button,Spinner, Modal } from '@salesforce/design-system-react';
import { Actions } from './CustomActionConstants';

export const CustomAction = forwardRef((props, ref) => {

    const [isOpen, setIsOpen] = useState(false);
    const renderedComponentRef = useRef(null);
    const [modalTitle, setModalTitle] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [target, setCurrentTarget] = useState({});
    const [_, forceUpdate] = useReducer(x => x + 1, 0);
    const [size, setSize] = useState('small');

    useImperativeHandle(ref, () => ({
        setTitle(title) {
            setModalTitle(title);
        },
        setTarget(id, objectName, action) {
            setCurrentTarget({
                id: id,
                objectName: objectName,
                action: action
            });
        },
        toggle() {
            setIsOpen(!isOpen);
        }
    }));

    const renderedCallback = () => {
        forceUpdate();
    }

    const buttonLabel = renderedComponentRef?.current?.hasNextStep() ? 'Próximo' : 'Salvar';

    const footer = [<Button label="Cancel" onClick={() => setIsOpen(!isOpen)} />,
        <Button label={buttonLabel} variant="brand" onClick={() => onSave()} disabled={isLoading} />];

    const RenderComponent = Actions[target?.action?.componentName];

    const onSave = () => {
        if (renderedComponentRef.current.onSave) {
            renderedComponentRef.current.onSave();
        }
    }

    if (RenderComponent) {
        return (<Fragment>
            <Modal
                dismissOnClickOutside={false}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(!isOpen)}
                heading={modalTitle}
                footer={footer}
                size={size} >

                <Card hasNoHeader={true}>
                    <div className="slds-p-around_small">
                        {isLoading && (<Spinner size="small" variant="base" />)}
                        {isOpen && (<RenderComponent
                            {...props}
                            {...target}
                            isLoading={isLoading}
                            toggle={() => setIsOpen(!isOpen)}
                            setIsLoading={setIsLoading}
                            ref={renderedComponentRef}
                            callBack={renderedCallback}
                            setSize={setSize}>
                        </RenderComponent>)}
                    </div>
                </Card>
            </Modal>
        </Fragment>);
    } else {
        return (<Fragment></Fragment>);
    }
});