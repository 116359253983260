import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { Button, Modal, Input, Card, Combobox, IconSettings, Checkbox, Datepicker, Spinner, Textarea } from '@salesforce/design-system-react';
import authService from '../api-authorization/AuthorizeService';
import moment from 'moment';
import 'moment/locale/pt-br';
import { LayoutContext } from '../Layout';
import CurrencyInput from 'react-currency-input-field';

export const EditFormModal = forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const [target, setCurrentTarget] = useState({});
    const editForm = useRef(null);

    useImperativeHandle(ref, () => ({
        setTitle(title) { 
            setModalTitle(title);
        },
        setTarget(id, objectName, standardValues) {
            setCurrentTarget({
                id: id,
                objectName: objectName,
                action: id ? 'Update' : 'Create',
                standardValues: standardValues
            });
        },
        toggle() {
            setIsOpen(!isOpen);
        }
    }));

    const onSave = async () => {
        setIsLoading(true);
        editForm.current.onSaveRecord().then((response) => {
            if (response.success) {
                if (props.onSuccess) {
                    props.onSuccess();
                }

                setIsOpen(!isOpen);
            }
            setIsLoading(false);
        });
    }

    return (<Fragment>
        <Modal
            dismissOnClickOutside={false }
            isOpen={isOpen}
            footer={[
                <Button label="Cancel" onClick={() => setIsOpen(!isOpen)} />,
                <Button label="Save" variant="brand" onClick={() => onSave()} disabled={isLoading}>
                </Button>
            ]}
            onRequestClose={() => setIsOpen(!isOpen)}
            heading={modalTitle} >

            <Card hasNoHeader={true}>
                <div className="slds-p-around_small">
                    {isLoading && (<Spinner size="small" variant="base" />)}
                    {isOpen && (<EditForm {...target} ref={editForm}></EditForm>)}
                </div>
            </Card>
        </Modal>
    </Fragment>);
});

export const EditForm = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { notifyToast, api } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        onSaveRecord() {
            return new Promise((resolve, reject) => {
                saveRecord().then((response) => {
                    resolve(response);
                });
            });
        }
    }));

    const saveRecord = async () => {
        if (validateForm()) {
            const token = await authService.getAccessToken();
            const response = await fetch('basicadmininstration/' + props.action, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json;"
                },
                method: "POST",
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (result.success) {
                const message = props.action === 'Create' ? 'Registro inserido com sucesso' : 'Registro alterado com sucesso';
     
                notifyToast({
                    title: message,
                    static: false,
                    variant: 'success'
                })
                
            } else {
                notifyToast({
                    title: 'Erro ao salvar registro',
                    body: result.errors.join(' \n ') ,
                    static: true,
                    variant: 'error'
                })
            }

            return result;

        } else {
            setData({ ...data });

            return { success: false };
        }
    }

    const validateForm = () => {
        let allValid = true;

        data.lines.forEach((line) => {
            line.values.forEach((columnValue) => {
                const isValid = validateField(columnValue);
                if (!isValid) {
                    allValid = false;
                }
            });
        });

        return allValid;
    }

    const validateField = (columnValue) => {

        let column = data.fields.find(a => a.name === columnValue.fieldName);

        if (!column || column.isReadOnly) {
            return true;
        }

        if (column.isRequired && (columnValue.value === null || columnValue.value === undefined || columnValue.value === "")) {
            column["errorMessage"] = "Campo obrigatório";
        } else {
            column["errorMessage"] = "";
        }

        return column["errorMessage"] === "";
    }

    const getRecord = async () => {

        const objectName = props.objectName;
        const recordId = props.id;

        const tableListaParam = {
            objectName: objectName,
            id: recordId
        };

        const params = new URLSearchParams(tableListaParam).toString();

        const result = await api.doGET('basicadmininstration/getRecord?' + params);

        if (!result.success) {
            notifyToast({
                title: 'Erro ao obter objeto',
                body: result.errors.join(' \n '),
                static: true,
                variant: 'error'
            })
        }
        setData(result);
        setLoading(false);
    }

    const getObject = async () => {

        const objectName = props.objectName;
        const token = await authService.getAccessToken();

        const tableListaParam = { objectName: objectName };

        const params = new URLSearchParams(tableListaParam).toString();

        const response = await fetch('basicadmininstration/getObject?' + params, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json;"
            },
            method: "GET"
        });

        const result = await response.json();

        if (!result.success) {
            notifyToast({
                title: 'Erro ao obter objeto',
                body: result.errors.join(' \n '),
                static: true,
                variant: 'error'
            })
        } else {
            if (props.standardValues && props.standardValues.length > 0) {
                result.lines.forEach((line) => {
                    line.values.forEach((lineValue) => {
                        let fieldValue = props.standardValues.find(a => a.fieldName === lineValue.fieldName);
                        if (fieldValue) {
                            lineValue.value = fieldValue.value;
                        }
                    })
                });
            }
        }

        setData(result);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);

        if (props.action === 'Create') {
            getObject();
        } else {
            getRecord();
        }
    }, []);

    const fields = data?.fields?.map((field) => {

        if (data.lines.length > 0) {
            const line = data.lines[0];
            const fieldValue = line.values.find(a => a.fieldName === field.name);

            const onChange = (value, displayValue) => {
                fieldValue.value = value
                fieldValue.displayValue = (displayValue ?? (value ? '' : value)) + '';

                validateField(fieldValue);

                setData({ ...data });
            }

            return (<Fragment key={'edit-form-' + line.id + field.name}>
                <div className='slds-col slds-size_1-of-2 slds-p-around_small'>
                    <RenderFieldByType field={field} fieldValue={fieldValue} onChange={onChange} api={api} />
                </div>
            </Fragment>);
        }
    });

   
    return (<Fragment>
        <IconSettings iconPath="/assets/icons">
            <div className='slds-grid slds-wrap'>
                {fields}
            </div>
        </IconSettings>
    </Fragment>);
});

export const RenderFieldByType = (props) =>
{
    const api = props.api;
    const [field, fieldValue, onChange] = [props.field, props.fieldValue, props.onChange];
    const errorClass = field.errorMessage && field.errorMessage !== '' ? 'slds-has-error' : '';

    if (field.type.includes('Lookup')) {
        return (<Lookup field={field} fieldValue={fieldValue} onChange={(value, label) => onChange(value, label)} api={api} />);
    } else if (Object.keys(field.selectableValues).length > 0) {
        return (<PickList field={field} fieldValue={fieldValue} onChange={(value, label) => onChange(value, label)} />);
    } else if (field.type === 'Int32' || field.type === 'Int64') {
        return (<Input type='number' errorText={field.errorMessage} required={field.isRequired} value={fieldValue?.value}
            label={field.label} placeholder={field.label} readOnly={field.isReadOnly} onChange={(e) => {
                if (e.target.value && !Number.isNaN(e.target.value)) {
                    onChange(parseInt(e.target.value), e.target.value);
                } else {
                    onChange(e.target.value);
                }
            }} />);
    } else if (field.type === 'Decimal'){
        return (<div class={"slds-form-element " + errorClass} >
            <label class="slds-form-element__label">{field.isRequired && (<abbr class="slds-required" title="required">* </abbr>)}{field.label}</label>
            <div class="slds-form-element__control">
                <CurrencyInput
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    placeholder={'BRL'}
                    value={fieldValue?.displayValue ?? fieldValue.value}
                    decimalsLimit={2}
                    onValueChange={(value, name, values) => {
                        onChange(values.float, value);
                    }}
                    className="slds-input inputSearch"
                    decimalSeparator="," groupSeparator="."
                    allowDecimals={true}
                    readOnly={field.isReadOnly}
                />
            </div>
            <div class="slds-form-element__help">{field.errorMessage}</div>
        </div>);
    } else if (field.type === 'Double') {
        return (<div class={"slds-form-element " + errorClass}>
            <label class="slds-form-element__label">{field.isRequired && (<abbr class="slds-required" title="required">* </abbr>)} {field.label}</label>
            <div class="slds-form-element__control">
                <CurrencyInput
                    value={fieldValue?.displayValue ?? fieldValue.value}
                    decimalsLimit={2}
                    onValueChange={(value, name, values) => {
                        onChange(values.float, value);
                    }}
                    className="slds-input inputSearch"
                    decimalSeparator="," groupSeparator="."
                    allowDecimals={true}
                    readOnly={field.isReadOnly}
                />
            </div>
            <div class="slds-form-element__help">{field.errorMessage}</div>
        </div>);
    } else if (field.type === 'bool' || field.type === 'Boolean') {
        return (<div class={"slds-form-element " + errorClass}>
            <label class="slds-form-element__label">{field.isRequired && (<abbr class="slds-required" title="required">* </abbr>)}{field.label}</label>
            <div class="slds-form-element__control">
                <Checkbox checked={fieldValue?.value === true} onChange={(e) => onChange(e.target.checked)} />
            </div>
            <div class="slds-form-element__help">{field.errorMessage}</div>
        </div>);
    } else if (field.type === 'DateTime') {
        const date = fieldValue?.value ? new Date(fieldValue?.value) : null;
        return (<Fragment>
            <div class={"slds-form-element " + errorClass}>
            <Datepicker required={field.isRequired}
            value={date}
            label={field.label}
            placeholder={field.label}
            hasError={field.errorMessage}
            disabled={field.isReadOnly}
            onChange={(e, data) => {
                onChange(data.date)
            }}
            formatter={(date) => {
                moment.locale('pt-br');
                return date ? moment(date).format('DD/MM/YYYY') : '';
            }}
            parser={(dateString) => {
                return dateString;
            }}
        />
                <div class="slds-form-element__help">{field.errorMessage}</div>
            </div>
        </Fragment>);
    } else if (field.type === 'RichText') {
        return (<Textarea required={field.isRequired} errorText={field.errorMessage} value={fieldValue?.value} label={field.label} placeholder={field.label} onChange={(e) => onChange(e.target.value)} readOnly={field.isReadOnly} />);
    }
    else {
        return (<Input required={field.isRequired} errorText={field.errorMessage} value={fieldValue?.value} label={field.label} placeholder={field.label} onChange={(e) => onChange(e.target.value)} readOnly={field.isReadOnly} />);
    }
}

export const PickList = (props) => {
    const [field, setField] = useState(props.field ?? {});
    const [options, setOptions] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedOption, setSelectedOption] = useState();
    const [selection, setSelection] = useState([]);

    useEffect(() => {

        const newOptions = Object.keys(field.selectableValues).map((key) => {
            return { id: key, label: field.selectableValues[key] };
        });

        const selOpt = {
            id: props.fieldValue.value,
            label: props.fieldValue.displayValue
        };

        setSelectedOption(selOpt);
        setSelection([selOpt]);
        setOptions(newOptions);

    }, []);

    return (<Combobox
        events={{
            onSelect: (event, data) => {
                setSelection(data.selection);
                setSelectedOption(data.selection[0]);
                setErrorMessage('');
                props?.onChange(data.selection[0].id, data.selection[0].label);
            },
        }}
        labels={{
            label: field.label,
            placeholder: 'Pesquisar',
        }}
        options={options}
        selection={selection}
        value={selectedOption ? selectedOption.label : ''}
        required={props.field.isRequired}
        errorText={props.field.errorMessage}
        variant="readonly"
        disabled={props.field.isReadOnly}
    />);

}

export const Lookup = (props) => {
    const [field, setField] = useState(props.field ?? {});
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedOption, setSelectedOption] = useState();
    const [searchName, setSearchName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [selection, setSelection] = useState([]);
    const api = props.api;

    useEffect(() => {
        setField(props.field);
    }, [props.field])

    useEffect(() => {

        getRecords(field);

        if (props.fieldValue.value) {

            if (!props.fieldValue.displayValue) {
                getRecord(field, props.fieldValue.value).then(result => {
                    const selOpt = {
                        id: props.fieldValue.value,
                        label: result.lines[0].values.find(a => a.fieldName === 'Name').value
                    };

                    setSelectedOption(selOpt);
                    setSelection([selOpt]);
                });
            } else { 
                const selOpt = {
                    id: props.fieldValue.value,
                    label: props.fieldValue.displayValue
                };

                setSelectedOption(selOpt);
                setSelection([selOpt]);
            }
        }
    }, [field]);

    const getRecord = (fieldParam, id) => {
        const tableListaParam = {
            objectName: fieldParam.type.split('=')[1],
            id: id
        };

        const params = new URLSearchParams(tableListaParam).toString();

        return api.doGET('basicadmininstration/getRecord?' + params); 
    }

    const getRecords = async (fieldParam) => {

        const tableListaParam = {
            objectName: fieldParam.type.split('=')[1],
            name: searchName
        };

        const params = new URLSearchParams(tableListaParam).toString();
        const result = await api.doGET('basicadmininstration/getLookupRecord?' + params);

        let recordsResponse = [];

        if (result.success) {
            recordsResponse = result.lines.map(line => {
                return {
                    id: line.values.find(a => a.fieldName === 'id').value,
                    label: line.values.find(a => a.fieldName === 'Name').value,
                    subTitle: '',
                    type: result.name
                }
            });
        }

        setRecords(recordsResponse);
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getRecords(field);
    }, [searchName])

    return (<Combobox
        events={{
            onChange: (event, { value }) => {
                setSearchName(value);
            },
            onRequestRemoveSelectedOption: (event, data) => {
                if (!props.field.isReadOnly) { 
                    setSearchName('');
                    setSelection(data.selection);
                    setSelectedOption(null);
                    if (field.isRequired) {
                        setErrorMessage('Este campo é obrigatório');
                    }
                    props?.onChange(null, null);
                }
            },
            onSubmit: (event, { value }) => {
                setSearchName(value);
            },
            onSelect: (event, data) => {
                setSearchName('');
                setSelection(data.selection);
                setSelectedOption(data.selection[0]);
                setErrorMessage('');
                props?.onChange(data.selection[0].id, data.selection[0].label);
            },
        }}
        labels={{
            label: field.label,
            placeholder: 'Pesquisar',
        }}
        readOn
        options={records}
        selection={selection}
        value={selectedOption ? selectedOption.label : searchName}
        required={props.field.isRequired}
        errorText={props.field.errorMessage}
        variant={"inline-listbox"}
        singleInputDisabled={props.field.isReadOnly}
        hasInputSpinner={isLoading}
    />);
}