import React, { useCallback, createContext, useRef, useEffect, useState } from 'react';
import { NavMenu } from './NavMenu';
import { BrandBand } from '@salesforce/design-system-react';
import { Toast } from './common/slds/slds-react';
import { ApiRequester } from './common/ApiRequester'

export const LayoutContext = createContext(null);

export const Layout = ({ children, ...props }) => {
    const toast = useRef(null);
    const apiRequester = useRef(null);
    const [matches, setMatches] = useState(window.innerWidth > 768);

    const notifyToast = useCallback((toastArgs) => {
        if (toast) {
            toast.current.fire(toastArgs);
        }
    }, []);

    useEffect(() => {
        window.matchMedia("(min-width: 768px)").addEventListener('change', e => setMatches(e.matches));
    }, []);

    const doGET = useCallback(async (url) => {
        return await apiRequester.current.doGET(url);
    }, []);

    const doPOST = useCallback( async (url, parameters) => {
        return await apiRequester.current.doPOST(url, parameters);
    }, []);

    return (<LayoutContext.Provider value={{
        notifyToast: notifyToast,
        api: { doPOST: doPOST, doGET: doGET },
        matches: matches }}>
        <div>
            <NavMenu />
            <Toast ref={toast}></Toast>
            <ApiRequester ref={apiRequester} />
            <BrandBand id="brand-band-default" />
            <div className='slds-m-around_medium'>
                {children}
            </div>
        </div>
    </LayoutContext.Provider>);
}
