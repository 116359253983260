import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Home } from "./components/Home";
import { Signin } from "./components/api-authorization/Signin";
import { TableList } from "./components/Administration/TableList";
import { Detail } from "./components/Administration/Detail";

const AppRoutes = [
    {
        path: '/login',
        index: true,
        element: <Signin />
    },
    {
        path: '/',
        element: <Home />,
        requireAuth: true
    },
    {
        path: '/:object/list',
        element: <TableList />
    },
    {
        path: '/:object/detail/:recordId',
        element: <Detail />
    },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
