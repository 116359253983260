import { useState, useEffect, Fragment } from "react";
import { Spinner, Pagination, PaginationItem, PaginationLink, Table, Tooltip, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import symbols from '../../../customstyles/slds/icons/utility-sprite/svg/symbols.svg';
import './brskins-react-strap.css';
import moment from 'moment-timezone';

const defaultLocale = 'pr-br';
const defaultCurrency = 'BRL';

export const Popovering = ({ children, ...attributes }) => {
    const [isOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!isOpen);

    return (<Fragment>
        <Popover isOpen={isOpen} toggle={toggle} target={attributes.target} placement={attributes.placement } >
            <PopoverHeader className={attributes.headerClassName}>
                <div className='slds-grid'>
                    <div>
                        {attributes.headerLabel}
                    </div>
                    <div className="slds-col_bump-left">
                        <button className="btn-close btn-close-white" type="button" onClick={toggle}></button>
                    </div>
                </div>
            </PopoverHeader>
            <PopoverBody className={attributes.bodyClassName}>
                {children}
            </PopoverBody>
        </Popover>
    </Fragment>);
}

export const Tooltipper = ({ children, ...attributes }) =>{
    const [isOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!isOpen);
    const isUncontrolled = attributes.uncontrolled;
    delete attributes.uncontrolled;

    const newAttr = { ...attributes }

    return (<Fragment>
        <span id={attributes.target} {...newAttr}>
            {children}
        </span>

        {!isUncontrolled && (<Tooltip
            {...attributes}
            autohide={true}
            flip={true}
            isOpen={isOpen}
            toggle={toggle}>
            {attributes.value}
        </Tooltip>)}

        {isUncontrolled && (<UncontrolledTooltip
            {...attributes}
            autohide={true}
            flip={true}
            trigger="click">
            {attributes.value}
        </UncontrolledTooltip>)}
    </Fragment>)
}

export const Paginator = (props) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [isLoading, setLoading] = useState(props.isLoading);

    useEffect(() => {
        setLoading(props.isLoading);
    }, [props.isLoading]);

    const totalPages = props.totalPages.toFixed(0) ?? 1;
    const firstPage = 1;
    const displayPageItem = props.maxPage ?? 9;
    const range = (displayPageItem - 1) / 2;
    const smallRange = range / 2;
    let startIndex = pageIndex - range < firstPage ? firstPage : pageIndex - range;
    const endIndex = startIndex + (displayPageItem - 1) > totalPages ? totalPages : startIndex + (displayPageItem - 1);
    const resto = pageIndex - range < firstPage ? displayPageItem - pageIndex - range : 0;
    const restoFim = startIndex + (displayPageItem - 1) > totalPages ? (startIndex + (displayPageItem - 1)) - totalPages : 0;

    startIndex = startIndex - restoFim < 1 ? 1 : startIndex - restoFim;

    const changePage = async (currentIndex) => {
        if (pageIndex === currentIndex || currentIndex < 1 || currentIndex > totalPages) {
            return;
        }

        setPageIndex(currentIndex);
        props.onClick(currentIndex - 1);
    }

    let paginationItems = [];

    for (let i = startIndex; i <= endIndex; i++) {
        if (i === startIndex && pageIndex - range - restoFim === startIndex) {
            paginationItems.push(newPaginationItem(1, pageIndex, '1', changePage, true));
        } else if ((i > pageIndex - range - restoFim && i < pageIndex - smallRange - restoFim && i !== startIndex && i !== firstPage + 1) ||
            (i < pageIndex + range + resto && i > pageIndex + smallRange + resto && i !== totalPages && i !== totalPages - 1)) {
            paginationItems.push(newPaginationItem(i, pageIndex, '...', changePage, false));
        } else if (i === endIndex && pageIndex + smallRange < totalPages) {
            paginationItems.push(newPaginationItem(totalPages, pageIndex, totalPages, changePage, true));
        }
        else {
            paginationItems.push(newPaginationItem(i, pageIndex, i, changePage, true));
        }
    }

    return (<div className='slds-align_absolute-center'>

        {isLoading && (<div className="slds-align_absolute-center">
            <Spinner> 
                Loading...
            </Spinner>
        </div>)}
        {!isLoading && (<Pagination size={props.size ?? 'sm'} >
            {newPaginationItem(pageIndex - 1, pageIndex, '<', changePage, true)}
            {paginationItems}
            {newPaginationItem(pageIndex + 1, pageIndex, '>', changePage, true)}
        </Pagination>)}
    </div>);
}

const newPaginationItem = (pageIndex, currentPage, label, changePage, isClickable) => {
    const paginationLink = isClickable ? (<PaginationLink onClick={() => changePage(pageIndex)} className='brskins-page-link'> {label} </PaginationLink>) :
        (<PaginationLink className='brskins-page-link'> {label}  </PaginationLink>)

    return (<PaginationItem className='brskins-page-item' key={'pag' + pageIndex} active={pageIndex === currentPage} >
        {paginationLink}
    </PaginationItem>);
}

export const ReactSpinner = ({ children, ...attributes }) => {

    const currStyle = {
        height: 'inherit',
        width: 'inherit',
        minHeight: 'inherit',
        maxHeight: 'inherit',
        minWidth: 'inherit',
        maxWidth: 'inherit'
    }

    if (attributes.loading) {
        currStyle['position'] = 'relative';
    }

    return (<Fragment>
        <div style={currStyle} {...attributes}>
            {attributes.loading && (<div className="slds-spinner_container slds-align_absolute-center spinner-background slds-p-around_small">
                <Spinner style={{ height: '3rem', width: '3rem' }} color="light">
                    Loading...
                </Spinner>
            </div>)}
           {children}
        </div>
    </Fragment>);
}

export const Icon = (props) => {
    if (!props.iconName) {
        return;
    }

    const ref = props.iconRef ?? symbols;
    const size = 'slds-icon_' + props.iconSize ?? 'small'

    return (<Fragment>
        <span title={props.iconAlternativeText}>
            <svg className={"slds-icon " + size} >
                <use xlinkHref={ref + "#" + props.iconName} className={props.iconFill}></use>
            </svg>
        </span>
    </Fragment>);
}

export const IconDefinition = () => {
    return (<Fragment>
        <svg style={{ height: '0px' } }>
            <defs>
                <linearGradient gradientTransform="rotate(-30)" id="icon-hot-gradient">
                    <stop offset="0%" stopColor="var(--orange)" />
                    <stop offset="30%" stopColor="var(--dark-orange)" />
                    <stop offset="60%" stopColor="var(--radious-red)" />
                    <stop offset="80%" stopColor="var(--dark-deep-black)" />
                </linearGradient>
            </defs>
        </svg>
    </Fragment>);
}

export const FormatedValue = (props, value) => {
    const formatedValue = formatValue(props, value);

    return (<span className={props.className}>
        {formatedValue.value}
    </span>);
}

export const DataTable = (props) => {

    if (!props.cardMode) {
        return dataTableGrid(props);
    } else {
        return dataTableCard(props);
    }
}

const dataTableCard = (props) => {
    const columns = props.columns ?? [];
    const data = props.data ?? [];

    const rowsDisplay = data.map((row, idx) => {
        const innerRow = columns.map((column, cIdx) => {
            const key = 'line' + cIdx + '-' + idx;
            const className = column.cellAttributes?.class ?? '';

            return (<Fragment key={key}>
                <div className='slds-col slds-size_1-of-3'>
                    <div className='slds-text-color_inverse-weak slds-p-left_xx-small'>
                        {column.label}:
                    </div>
                </div>
                <div className={'slds-col slds-size_2-of-3 ' + className}>
                    {dataTableCell(column, row, props)}
                </div>
            </Fragment>);
            
        });

        let lineBackground = '';

        if (props.striped) {
            lineBackground = idx % 2 === 1 ? 'brskins-tab-background' : 'brskins-card-background';
        }

        return (<div className={"slds-col slds-size_1-of-1 slds-p-around_xx-small grid-card-item " + lineBackground}>
            <div className="slds-grid slds-wrap">
                {innerRow}
            </div>
        </div>);
    });

    return (<div className='slds-grid slds-wrap slds-p-bottom_small'>
            {rowsDisplay}
     </div>);
}

const dataTableGrid = (props) => {
    const columns = props.columns ?? [];
    const data = props.data ?? [];

    const columnsDisplay = columns.map((column, idx) => {
        return (<Fragment key={'column' + idx} >
            <th className={column.class }> {column.label} </th>
        </Fragment>);
    });

    const rowsDisplay = data.map((row, idx) => {
        const innerRow = columns.map((column, cIdx) => {
            const key = 'line' + cIdx + '-' + idx;
            const className = column.cellAttributes?.class ?? '';

            if (cIdx === 0) {
                return (<th key={key} scope="row" className={className} >
                    {dataTableCell(column, row, props)}
                </th>);
            } else {
                return (<td key={key} {...column.cellAttributes} className={className}>
                    {dataTableCell(column, row, props)}
                </td>);
            }
        });

        let lineBackground = '';

        if (props.striped) {
            lineBackground = idx % 2 === 1 ? 'brskins-tab-background' : 'brskins-card-background';
        }

        return (<tr key={'line' + idx} className={lineBackground}>{innerRow}</tr>);
    });

    return (<Table>
        <thead>
            <tr className='text-white'>
                {columnsDisplay}
            </tr>
        </thead>
        <tbody className='text-white'>
            {rowsDisplay}
        </tbody>
    </Table>);
}


const dataTableCell = (column, row, props) => {
    const value = row[column.fieldName];
    const iconRightPosition = column.cellAttributes?.iconPosition ?? 'right' === 'right';

    let icon;

    if (column.cellAttributes?.iconName) {
        icon = addIcon(column, row);
    }

    let className = column.cellAttributes?.class ?? '';

    if (column.cellAttributes?.class?.fieldName) {
        className = row[column.cellAttributes?.class?.fieldName];
    }

    const formatedValue = formatValue(column, value);
    const alignment = column.cellAttributes?.alignment ?? formatedValue.alignment;

    let keyColumn = '';
    if (row[props.keyColumn]) {
        keyColumn = row[props.keyColumn];
    }

    if (column.customCell) {
        return (<column.customCell row={row} value={formatedValue.value} events={column.events} key={`cell_${column.fieldName}_${keyColumn}`} ></column.customCell>);
    } else {
        return (<div className={'slds-grid slds-grid_align-' + alignment} key={`cell_${column.fieldName}_${keyColumn}` }>
            <div className={'slds-truncate ' + className}>
                {!iconRightPosition && (<Fragment>{icon}</Fragment>)}
                {formatedValue.value}
                {iconRightPosition && (<Fragment>{icon}</Fragment>)}
            </div>
        </div>);
    }
}

const addIcon = (column, row) => {
    let iconName = column.cellAttributes.iconName;
    let iconFill = column.cellAttributes.iconFill;
    let iconAlternativeText = column.cellAttributes.iconAlternativeText;

    if (column.cellAttributes.iconName?.fieldName) {
        iconName = row[column.cellAttributes.iconName.fieldName];
    }

    if (column.cellAttributes.iconFill?.fieldName) {
        iconFill = row[column.cellAttributes.iconFill.fieldName];
    }

    if (column.cellAttributes.iconAlternativeText?.fieldName) {
        iconAlternativeText = formatValue(column.cellAttributes.iconAlternativeText,
                                            row[column.cellAttributes.iconAlternativeText.fieldName]).value;
    }

    return (<Icon
        iconName={iconName}
        iconRef={column.cellAttributes.iconRef}
        iconSize={column.cellAttributes.iconSize}
        iconFill={iconFill}
        iconAlternativeText={iconAlternativeText}>
    </Icon>);
}

const formatValue = (props, value) => {
    if (!props || !value) {
        return { value: '', alignment: props?.cellAttributes?.alignment ?? 'left'};
    }

    let formatedValue;
    switch (props.type?.toUpperCase()) {
        case 'DATETIME':
            formatedValue = formatDateTime(props, value);
            break;
        case 'DATE':
            formatedValue = formatDate(props, value);
            break;
        case 'CURRENCY':
            formatedValue = formatCurrency(props, value);
            break;
        case 'ENUM':
            formatedValue = formatEnumerator(props, value);
            break;
        case 'PERCENT':
            formatedValue = formatPercent(props, value);
            break;
        default:
            formatedValue = formatText(props, value);
    }

    return formatedValue;
}

export const formatDateTime = (props, value) => {

    const currentTimezone = moment.tz.guess();

    const timezone = process.env.REACT_APP_TIME_ZONE;

    let timezoneOffset = '-03:00';
    if (timezone === 'Canada/Pacific') {
        timezoneOffset = '-08:00';
    }
    
    return {
        value: moment(value).tz(currentTimezone).format('DD/MM/YYYY HH:mm:ss'),
        alignment: props.alignment ?? 'spread'
    };
}

const formatDate = (props, value) => {
    const locale = props.locale ?? defaultLocale;
    return {
        value: new Date(Date.parse(value)).toLocaleDateString(locale),
        alignment: props.alignment ?? 'spread'
    };
}

export const formatCurrency = (props, value) => {
    const currency = props.currency ?? defaultCurrency;
    const locale = props.locale;

    return {
        value: value.toLocaleString(locale, { style: 'currency', currency: currency }),
        alignment: props.alignment ?? 'end'
    };
}

export const formatPercent = (props, value) => {
    const locale = props.locale;

    return {
        value: value.toLocaleString(locale, { style: 'percent' }),
        alignment: props.alignment ?? 'end'
    };
}

const formatEnumerator = (props, value) => {
    return {
        value: value,
        alignment: props.alignment ?? 'spread'
    };
}

const formatText = (props, value) => {
    return {
        value: `${value}`,
        alignment: props.alignment ?? 'spread'
    };
}