import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Input, Button, Combobox, Popover, Checkbox } from '@salesforce/design-system-react';
import { LayoutContext } from '../../Layout';
import authService from '../../api-authorization/AuthorizeService';

export const LayoutConfiguration = forwardRef((props, ref) => {

    const [data, setData] = useState({});
    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() { return false; },
		onSave() {
			props.setIsLoading(true);
			saveRecord();
		}
    }));

	useEffect(() => {
		props.setIsLoading(true);
        getObject();
    },[]);

    const getObject = async () => {

        const objectName = props.objectName;
        const token = await authService.getAccessToken();

        const tableListaParam = { objectName: objectName };

        const params = new URLSearchParams(tableListaParam).toString();

        const response = await fetch('basicadmininstration/getTableParams?' + params, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json;"
            },
            method: "GET"
        });

        const result = await response.json();

        if (!result.success) {
            notifyToast({
                title: 'Erro ao obter objeto',
                body: result.errors.join(' \n '),
                static: true,
                variant: 'error'
            })
        }

		setData(result);
		props.setIsLoading(false);
	}

	const saveRecord = async () => {

		const token = await authService.getAccessToken();
		const response = await fetch('basicadmininstration/setTableParams', {
			headers: !token ? {} : {
				'Authorization': `Bearer ${token}`,
				"Content-Type": "application/json;"
			},
			method: "POST",
			body: JSON.stringify(data)
		});

		const result = await response.json();

		props.setIsLoading(false);

		if (result.success) {
			const message = 'Registro alterado com sucesso';

			notifyToast({
				title: message,
				static: false,
				variant: 'success'
			})

			props.onSuccess();
		} else {
			notifyToast({
				title: 'Erro ao salvar registro',
				body: result.errors.join(' \n '),
				static: true,
				variant: 'error'
			})
		}

	}

	const getIsChecked = (viewName, fieldName) => {
		return data[viewName].includes(fieldName);
	}

	const handleCheckboxChange = (viewName, checked, target, field) => {
		if (checked) {
			data[viewName].push(field);
		} else {
			data[viewName].splice(data[viewName].indexOf(field), 1);
		}

		setData({ ...data });
	}

	return (<Fragment>
		<div style={{ height: '500px' }}>
			{data.name && (
				<div className='slds-grid slds-wrap'>
                    <div className='slds-col slds-size_1-of-1'>
						<Combobox
							assistiveText={{
								popoverLabel: 'List view',
							}}
							id="list-view-options"
							labels={{
								label: 'List view',
								placeholder: '',
							}}
							popover={
								<Popover
									body={
										<div style={{ height: '150px', overflowY: 'scroll' }}>
											<fieldset className="slds-form-element">
												<legend className="slds-form-element__legend slds-form-element__label">
													Selecione os campos
												</legend>
												<div className="slds-form-element__control">
													{data.fields.map((field, i) => (
														<Checkbox
															checked={getIsChecked('listView', field.name)}
															id={`checkbox-lv-${field.name}`}
															key={`checkbox-${i + 1}`}
															labels={{ label: field.name }}
															onChange={(e, { checked }) => {
																handleCheckboxChange('listView', checked, e.target, field.name);
															}}
														/>
													))}
												</div>
											</fieldset>
										</div>
									}
									onClose={() => { }}
								/>
							}
							selection={data['listView']}
							value={`${data['listView'].length} campos selecionados`}
							variant="popover"
						/>
				</div>
					<div className='slds-col slds-size_1-of-1'>
						<Combobox
							assistiveText={{
								popoverLabel: 'Detail layout',
							}}
							id="list-view-options"
							labels={{
								label: 'Detail layout',
								placeholder: '',
							}}
							popover={
								<Popover
									body={
										<div style={{ height: '150px', overflowY: 'scroll' }}>
											<fieldset className="slds-form-element">
												<legend className="slds-form-element__legend slds-form-element__label">
													Selecione os campos
												</legend>
												<div className="slds-form-element__control">
													{data.fields.map((field, i) => (
														<Checkbox
															checked={getIsChecked('detailLayout', field.name)}
															id={`checkbox-lv-${field.name}`}
															key={`checkbox-${i + 1}`}
															labels={{ label: field.name }}
															onChange={(e, { checked }) => {
																handleCheckboxChange('detailLayout', checked, e.target, field.name);
															}}
														/>
													))}
												</div>
											</fieldset>
										</div>
									}
									onClose={() => { }}
								/>
							}
							selection={data['detailLayout']}
							value={`${data['detailLayout'].length} campos selecionados`}
							variant="popover"
						/>
				</div>
					<div className='slds-col slds-size_1-of-1'>
						<Combobox
							assistiveText={{
								popoverLabel: 'Edit layout',
							}}
							id="list-view-options"
							labels={{
								label: 'Edit layout',
								placeholder: '',
							}}
							popover={
								<Popover
									body={
										<div style={{ height: '150px', overflowY: 'scroll' }}>
											<fieldset className="slds-form-element">
												<legend className="slds-form-element__legend slds-form-element__label">
													Selecione os campos
												</legend>
												<div className="slds-form-element__control">
													{data.fields.map((field, i) => (
														<Checkbox
															checked={getIsChecked('editLayout', field.name)}
															id={`checkbox-lv-${field.name}`}
															key={`checkbox-${i + 1}`}
															labels={{ label: field.name }}
															onChange={(e, { checked }) => {
																handleCheckboxChange('editLayout', checked, e.target, field.name);
															}}
														/>
													))}
												</div>
											</fieldset>
										</div>
									}
									onClose={() => { }}
								/>
							}
							selection={data['editLayout']}
							value={`${data['editLayout'].length} campos selecionados`}
							variant="popover"
						/>
				</div>
			</div>
			
		)}
		</div>
    </Fragment>);
});