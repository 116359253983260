import React, { Fragment, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { LayoutContext } from '../../../Layout';
import { Paginator, DataTable } from '../../../common/react-ext/brskins-react-strap';
import { Popover, Button } from '@salesforce/design-system-react';

export const PendingBotOffers = forwardRef((props, ref) => {
    const [data, setData] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const { api, notifyToast, matches } = useContext(LayoutContext);
    const [pagination, setPagination] = useState({ pageSize: 100, totalRecords: 0, pageIndex: 0 });
    const [selectedAssets, setSelectedAssets] = useState({});

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            props.setIsLoading(true);
            onSaveFunction();
        }
    }));

    useEffect(() => {
        props.setSize('large');
        props.setIsLoading(true);
        props.callBack();
        getBotPendingOffers();
    }, []);

    const onSaveFunction = async () => {

        let arrItems = [];
        let selectedAssetCodes = [];
        Object.keys(selectedAssets).forEach((key) => {
            if (selectedAssets[key].selected) {
                arrItems.push({
                    id: key,
                });
                selectedAssetCodes.push(key);
            }
        });

        const form = {
            botId: props.id,
            tradeOffers: arrItems,
            action:"Approve"
        };

        const result = await api.doPOST('bot/trade-offer-action', form);

        if (result.success) {
            setSelectedAssets({});
            getBotPendingOffers();
        } else {
            setErrorMessage(`${result.errorCode} ${result.errors[0]}`);
        }

        props.setIsLoading(false);
        props.callBack();
    }

    const onChangeSelected = (row) => {
        if (selectedAssets[row.id]) {
            selectedAssets[row.id] = {
                selected: !selectedAssets[row.id].selected,
                row: row
            };
        } else {
            selectedAssets[row.id] = {
                selected: true,
                row: row
            };
        }

        setSelectedAssets({ ...selectedAssets })
    }

    const columns = [
        {
            fieldName: 'isSelected', label: '', type: 'Custom', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            },
            customCell: CustomCell,
            events: onChangeSelected,
            editable: true
        },
        {
            fieldName: 'id', label: 'Id', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'message', label: 'Message', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'state', label: 'State', class: 'slds-text-color_default', cellAttributes: {
                class: 'slds-text-color_default',
            }
        },
        {
            fieldName: 'isOurOffer', type: 'Custom', label: 'Is our offer', class: 'slds-text-color_default', cellAttributes: {
                class: 'slds-text-color_default',
            },
            customCell: CustomCellDisabled
        },
        {
            fieldName: 'created', type: 'DateTime', locale: 'pt-BR', label: 'Created', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default'
            }
        },
        {
            fieldName: 'updated', type: 'DateTime', locale: 'pt-BR', label: 'Updated', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default'
            }
        },
        {
            fieldName: 'expires', type: 'DateTime', locale: 'pt-BR', label: 'Expires', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default'
            }
        },
        {
            fieldName: 'scrowEnds', label: 'Tempo de retenção', class: 'slds-text-color_default',
            cellAttributes: {
                class: 'slds-text-color_default',
                alignment: !matches ? 'spread' : 'end'
            }
        },
        {
            fieldName: 'id', type: 'Custom', label: 'Is our offer', class: 'slds-text-color_default', cellAttributes: {
                class: 'slds-text-color_default',
            },
            customCell: ItemDetail
        }
    ];

    const getBotPendingOffers = async () => {
        const result = await api.doGET(`bot/get-pending-offers?botId=${props.id}`);

        setData(result);
        props.setIsLoading(false);
        props.callBack();
    }


    const totalPages = pagination.totalRecords / pagination.pageSize;

    const changePage = (pageIndex) => {
        if (pagination.pageIndex !== pageIndex) {
            setPagination({ pageSize: pagination.pageSize, totalRecords: pagination.totalRecords, pageIndex: pageIndex });
        }
    }

    return (<Fragment>
        <div className='slds-text-color_default' style={{ overflowX: 'scroll' }}>
            {errorMessage && (
                <div className='slds-col slds-size_1-of-1'>
                    <span className="slds-text-color_error">{errorMessage}</span>
                </div>
            )}
            {data.success && (<Fragment>
                <DataTable
                    columns={columns}
                    data={data.botPendingOffers}
                    striped={true}
                    cardMode={!matches}
                    keyColumn={"id"}
                >
                </DataTable>
                <Paginator totalPages={totalPages} onClick={changePage} isLoading={false}></Paginator>
            </Fragment>)}
        </div>
    </Fragment>);
});

export const CustomCell = (props) => {
    const [isSelected, setSelected] = useState(false);

    const handleInputChange = (e) => {
        setSelected(e.checked);
        props.events(props.row);
    }

    return <Fragment>
        <input type='checkbox' checked={isSelected} onClick={(e) => handleInputChange(e)}></input>
    </Fragment>
}

export const CustomCellDisabled = (props) => {
    return <Fragment>
        <input type='checkbox' checked={props.value} onClick={(e) => { return false; } }></input>
    </Fragment>
}

export const ItemDetail = (props) => {
    const myItens = props.row.itemsToGive.map((item) => {
        return (<div className='slds-size_1-of-1'>
            <div>{item.assetid} - {item.market_hash_name} - {item.listprice}</div>
        </div>)
    });

    const theirItens = props.row.itemsToReceive.map((item) => {
        return (<div className='slds-size_1-of-1'>
            <div>{item.assetid} - {item.market_hash_name} - {item.listprice}</div>
        </div>)
    });

    let itensDetail = (<div className='slds grid slds-wrap slds-container_large'>
        <div className='slds-size_1-of-1'>
            <div className='slds-size_1-of-1'>
                Meus itens:
            </div>
            {myItens}
        </div>
        <div className='slds-size_1-of-1 slds-border_top'>
            <div className='slds-size_1-of-1 slds-p-top_small'>
                Itens do usuário:
            </div>
            {theirItens}
        </div>
    </div>);

    return (<div>
        
        <Popover
            body={itensDetail}
            heading={'Itens da proposta de troca'}
            id={`pop${props.value}`}
            style={{width:'500px'} }
        >
            <Button label="Itens da troca"/>
        </Popover>
    </div>);
}