import React, { Fragment, useState, useEffect, createContext, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { Filter, IconSettings, Panel, PanelFilterGroup, Picklist, PanelFilterList, Input } from '@salesforce/design-system-react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Operators } from './FilterConstants';
import './TableFilter.css'
import { RenderFieldByType } from '../EditForm';
import { LayoutContext } from '../../Layout';

export const TableFilter = forwardRef((props, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [modifiedPanel, setModifiedPanel] = useState(false);
	const [filters, setFilters] = useState([]);
	const [savedFilter, setSavedFilter] = useState([]);

    useImperativeHandle(ref, () => ({
        toggle() {
            setIsOpen(!isOpen);
		},
		getFilters() {
			return filters;
		}
	}));

	useEffect(() => {
		setIsOpen(false);
		setModifiedPanel(false);
		setSavedFilter([]);
		setFilters([]);
	}, [props.object])

	const fildsOptions = props?.fields?.map(field => {
		return { label: field.name, value: field.name };
	}) ?? [];

	const onRemoveFilter = (idRemoved) => {
		setFilters(filters.filter(a => a.id !== idRemoved));
	}

	const onRemove = (event, { id }) => {
		onRemoveFilter(id);
	}

	const renderedFilters = filters.map((filter, idx) => {

		const onChangePredicate = (filterReturn) => {
			Object.keys(filterReturn).forEach((key) => {
				filter[key] = filterReturn[key];
			});

			setFilters([...filters]);
			setModifiedPanel(true);
		};

		return (<FilterItem key={`fitem-${filter.id}`} filter={filter} onChangeFilter={onChangePredicate} onRemove={onRemove} fildsOptions={fildsOptions} fields={props.fields} />);
	});

	const onAddFilter = () => {
		const maxId = filters.length > 0 ? Math.max(filters.map(filter => filter.id)) : 0;
		const newFilters = [...filters];
		const defaultFilterType = Operators.find(a => a.value === 'equals');

		newFilters.push({
			id: maxId + 1,
			name: '',
			nameValue: fildsOptions[0].value,
			predicate: 'Novo filtro',
			predicateValue: defaultFilterType.value,
			predicateLabel: defaultFilterType.label
		});

		setFilters(newFilters);
		setModifiedPanel(true);
	}

    return (<Fragment>
        <IconSettings iconPath="/assets/icons">
			{isOpen && (
			<div style={{ width: '350px' }}>
				<Panel>
						<PanelFilterGroup
							assistiveText={{ closeButton: 'Close Panel' }}
							modified={modifiedPanel}
							onClickAdd={() => {
								onAddFilter();
							}}
							onClickRemoveAll={() => {
								setFilters([]);
								setSavedFilter([]);
								setModifiedPanel(false);
								props.onSave([]);
							}}
							onRequestCancel={() => {
								setModifiedPanel(false);
								setFilters([...savedFilter ]);
							}}
							onRequestSave={() => {
								setModifiedPanel(false);
								setSavedFilter([ ...filters ]);
								props.onSave([ ...filters ]);
						}}
						variant="panel" >
						<PanelFilterList>
							{renderedFilters}
						</PanelFilterList>
					</PanelFilterGroup>
				</Panel>
			</div>
            )}
        </IconSettings>
    </Fragment>);
});

export const FilterItem = forwardRef((props, ref) => {

	useImperativeHandle(ref, () => ({
		getFilter() {
			return filter;
		}
	}));

	const { api } = useContext(LayoutContext);
	const [filter, setFilter] = useState({ ...props.filter });

	const field = props.fields.find(a => a.name === filter.nameValue);
	field.label = 'Valor'
	field.isRequired = false;
	field.isReadOnly = false;

	const onSelectOperator = (selectedItem) => {
		filter.nameValue = selectedItem.value;
		filter.value = '';
		filter.valueLabel = '';

		setFilter({ ...filter });
	};

	const onSelectField = (selectedItem) => {
		filter.predicateValue = selectedItem.value;
		filter.predicateLabel = selectedItem.label;

		setFilter({ ...filter });
	};

	const onChangePredicate = () => {

		if ((filter.value === null || filter.value === undefined || filter.value === '') && field.type === 'Boolean') {
			filter.value = false;
			filter.valueLabel = false;
		}

		const curreVal = filter.valueLabel ?? '';

		filter.predicate = filter.predicateLabel + ': ' + curreVal;
		filter.name = filter.nameValue;

		setFilter({ ...filter });

		props.onChangeFilter(filter);
	};

	const changeValue = (value, label) => {
		filter.value = value;
		filter.valueLabel = label ?? value;

		setFilter({ ...filter });
	}

	const fieldValue = {
		fieldName: filter.nameValue,
		value: filter.value,
		displayValue: filter.valueLabel
	}

	return (<Fragment>
		<Filter
			id={filter.id}
			isPermanent
			onChange={onChangePredicate}
			property={filter.name}
			predicate={filter.predicate}
			onRemove={props.onRemove}
		>
			<Picklist
				label="Campos"
				onSelect={(selectedItem) => onSelectOperator(selectedItem)}
				options={props.fildsOptions}
				value={filter.nameValue}
				className={'slds-button_stretch'}
				class={'slds-button_stretch'}
			/>

			<Picklist
				label="Operador"
				onSelect={(selectedItem) => onSelectField(selectedItem)}
				options={Operators}
				value={filter.predicateValue}
				className={'slds-button_stretch'}
			/>
			<RenderFieldByType field={field} fieldValue={fieldValue} onChange={changeValue} api={api} />
		</Filter>
	</Fragment>);
});