import React, { Fragment, useState, useEffect, createContext, useContext, useRef } from 'react';
import { Checkbox } from '@salesforce/design-system-react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

export const OutputText = ({ children, ...props }) => {

    let render;
    let title;
    if (props.type === 'Boolean') {
        render = (<Checkbox checked={children === 'true' || children === true} readonly={true} />);
    } else if (props.type.includes('Lookup') || (!props.dontLinkName && props.property === "Name")) {
        const objName = props.property === "Name" ? props.objectName : props.type.split('=')[1];
        const url = `/${objName}/detail/${props.item[props.property + 'ValueCode']}`;
        title = url;
        render = (<Link to={url} reloadDocument={true}>{children}</Link>);
    } else if (props.type === 'DateTime') {
        const currentTimezone = moment.tz.guess();

        const timezone = process.env.REACT_APP_TIME_ZONE;

        let timezoneOffset = '-03:00';
        if (timezone === 'Canada/Pacific') {
            timezoneOffset = '-08:00';
        }

        render = children ? moment(children + timezoneOffset).tz(currentTimezone).format('DD/MM/YYYY HH:mm:ss') : children;
        title = render;
    } else if (props.type === 'Decimal') {
        render = children ? parseFloat(children).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : children;
        title = render;
    } else {
        render = children;
        title = children;
    }

    return (<Fragment>
        <div className='slds-truncate' title={title}>
            {render}
        </div>
    </Fragment>);
}